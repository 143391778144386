.careerLife_info {
    h5 {
        width: 525px;
    }
}

.careerSearch {
    background-color: var(--bg-elevation);
    box-shadow: 0px 4px 8px var(--s3-top);
    border-radius: 16px;
    height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 26px;
    margin-top: 16px;
    margin-bottom: 20px;
}

.careerLife {
    margin-top: 64px;
    justify-content: space-between;
}

.careerSearch_position {
    display: flex;
    align-items: center;
    flex-grow: 0.77;

    input {
        width: 100%;
        height: 28px;
        background-color: transparent;
        border: 0;
        outline: 0;
        margin: 0 18px;
    }

    svg {
        cursor: pointer;
    }
}

.careerSearch_select {
    border-left: 1px solid var(--line-primary);
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 30px;
    margin-right: -64px;
    &.location {
        margin-right: -16px;
    }
}

.careerOpens {
    margin-bottom: 166px;
}

.careerList {
    --careerList-spacing: 16px;
    --careerList-column: 2;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(var(--careerList-spacing) * -1);
    margin-bottom: calc(var(--careerList-spacing) * -1);
}

.careerBox_item {
    width: calc(100% / var(--careerList-column) - var(--careerList-spacing));
    margin-left: var(--careerList-spacing);
    margin-bottom: calc(var(--careerList-spacing) + 4px);
}

.careerBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.careerBox_border {
    border-radius: 16px;
    padding: 16px 26px;
    background-color: var(--bg-secondary);
}

.careerBox_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.careerBox_info {
    padding-left: 24px;
}

.careerDetail_content {
    background-color: var(--bg-secondary);
    border-radius: 8px;
    padding: 20px 24px;
    margin-top: 16px;

    h5 {
        font-family: 'SVN-Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: var(--content-primary);
    }

    &.applying {
        background-color: var(--bg-primary);
        border: 0.5px solid var(--line-primary);
        box-shadow: 0px 4px 8px var(--s3-top);
    }
}

.careerDetail_lead {
    list-style: disc;
    margin-left: 24px;
    margin-top: 4px;
}

.careerDetail_wrapper {
    display: flex;
    margin-top: 52px;
    margin-bottom: 64px;
}

.careerDetail_left {
    flex-grow: 1;
    width: 100%;
}

.careerDetail_right {
    min-width: 320px;
    margin-left: 20px;

    .careerBox {
        padding: 14px 12px;
    }
}

.careerDetail_info {
    margin-top: 16px;
}

.career_heading {
    margin-top: 74px;
}

.career-slider {
    width: calc(100% - 560px);
    .slick-dots {
        bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    ul.list-dot {
        li {
            background: var(--bg-slider);
            border: 1px solid var(--border-slider-dot);
            width: 16px;
            height: 16px;
        }
        li.slick-active {
            background-color: var(--bg-accent-primary);
        }
    }
    .slick-prev:before,
    .slick-next:before {
        content: '';
    }
    .custom-arrow {
        background: var(--bg-slider);
        border: 1px solid var(--border-slider-dot);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 16px;
        height: 16px;
    }
}

@media (max-width: 980px) {
    .careerLife {
        flex-direction: column-reverse;
        margin-top: 12px;
    }

    .careerLife_info {
        width: 100%;
        text-align: center;
        margin-top: 24px;
        margin-right: 0;

        h3 {
            font-size: 40px;
        }

        h5 {
            width: 100%;
        }
    }

    .career_heading {
        text-align: center;
        margin-bottom: 24px;
        margin-top: 56px;
    }

    .careerSearch_position {
        flex-grow: 0;
        margin-right: 18px;
        input {
            display: none;
        }
    }

    .careerSearch {
        .selectDefaultScss {
            width: 100%;
        }
        &.openSearch {
            .careerSearch_position {
                width: 100%;
                input {
                    display: block;
                    width: 100%;
                }
            }
            .careerSearch_select {
                display: none;
            }
        }
    }

    .careerSearch_select {
        flex-grow: 1;
    }

    .careerList {
        --careerList-column: 1;
    }

    .careerBox_container {
        img {
            width: 48px;
        }
    }

    .careerBox_info {
        h4 {
            font-size: 16px;
        }
    }

    .careerBox_arrow {
        width: 18px;
    }

    .careerOpens {
        margin-bottom: 80px;
    }

    .creators_bg {
        margin-top: 50px;
        margin-bottom: 0;
    }
    .career-slider {
        width: 100%;
        max-width: 640px;
    }
    .career-slider ul.list-dot li {
        width: 8px;
        height: 8px;
    }

    .career-slider .custom-arrow {
        display: none;
        width: 8px;
        height: 8px;
    }
}

.formApply_mobile {
    .formChild {
        display: none;
    }
}

.formApply_title {
    display: none;
}

/* === start mobile === */
@media (max-width: 960px) {
    .formApply_desktop {
        background-color: transparent;
        .formApply {
            display: none;
        }
    }
    .careerDetail_wrapper {
        margin-top: 24px;
    }
    .careerDetail_right {
        display: none;
    }

    .careerDetail_info {
        margin-top: 24px;
    }

    .careerDetail_action {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        margin-top: 0;
        border-radius: 0;
        padding: 8px 16px 36px;
        background-color: var(--bg-primary);
        border: 0.5px solid var(--line-primary);
        box-shadow: 0px 4px 8px var(--s3-top);

        .buttonSimple {
            min-width: 100% !important;
        }
    }

    .career-path-detail {
        padding-bottom: 100px;
    }

    .formApply_mobile {
        top: 0;
        flex-direction: column;
        justify-content: space-between;
        top: 64px;
        border: 0;
        .formChild {
            display: block;
            overflow: auto;
            overflow-x: hidden;
            padding-bottom: 24px;
        }
    }

    .formApply_title {
        position: fixed;
        top: 0;
        left: 0;
        background-color: var(--bg-primary);
        box-shadow: 0px 1px 1px var(--s1-top);
        height: 64px;
        z-index: 4;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .formApply_title_iconClose {
        position: absolute;
        right: 14px;
        cursor: pointer;
        svg path {
            fill: var(--bg-close);
        }
    }
}

@media (max-width: 414px) {
}

@media (max-width: 360px) {
    .careerSearch {
        zoom: 0.8;
    }
}
